body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSansRegular"),
    url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
