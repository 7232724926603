input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
img {
  width: 100%;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-form {
  opacity: 0.9;
}

.form-control::placeholder {
  color: #bbb !important;
}

.card-header .collapsed .fa {
  transform: rotate(90deg);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
}

.contact {
  background: url("./shared/contact-background.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  height: calc(100vh - 56px);
}

.contact-header {
  background: url("./shared/contact-header.jpg");
  background-position: center;
  background-size: cover;
}

.contact-body.form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  padding: 0;
  transition: border-color 0.2s linear;
}

.contact-body.has-value.form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #57b846;
  border-radius: 0;
  padding: 0;
}

.contact-body.form-control:focus {
  box-shadow: none;
  border-bottom: 2px solid #57b846;
  backface-visibility: hidden;
}

.content {
  padding-bottom: 120px;
  margin-top: 24px;
}

.login {
  margin: auto;
  width: 350px;
}
.login .login-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}
.login .header {
  border-bottom: none;
  position: relative;
  justify-content: center;
  padding-bottom: 15px;
}

.login .form-group {
  margin-bottom: 20px;
}
.login label {
  font-weight: normal;
  font-size: 13px;
}
.login .form-control {
  min-height: 38px;
  padding-left: 5px;
  box-shadow: none !important;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.login .form-control:focus {
  border-color: #ccc;
}
.login .input-group-addon {
  text-align: center;
  background: none;
  border-width: 0 0 1px 0;
  padding-left: 5px;
  border-radius: 0;
}
.login .btn {
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
  border: none;
  min-width: 140px;
  outline: none !important;
}

.login .hint-text {
  text-align: center;
  padding-top: 5px;
  font-size: 13px;
}

.login .fa {
  font-size: 21px;
  vertical-align: middle;
  line-height: 35px;
}

footer ul li a {
  color: rgba(255, 255, 255, 0.5);
}

footer ul li a:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

footer {
  padding: 2rem 0;
  background-color: #343a40;
}

.footer-column:not(:first-child) {
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .footer-column:not(:first-child) {
    padding-top: 0rem;
  }
}

.footer-column {
  text-align: center;
}
.footer-column .nav-item .nav-link {
  padding: 0.1rem 0;
}
.footer-column .nav-item span.nav-link {
  color: #6c757d;
}
.footer-column .nav-item span.footer-title {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.footer-column .nav-item .fas {
  margin-right: 0.5rem;
}
.footer-column ul {
  display: inline-block;
}
@media (min-width: 768px) {
  .footer-column ul {
    text-align: left;
  }
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #050505;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: #343a40;
}

footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* .copyright {
  color: white;
} */
